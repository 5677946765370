<template>
  <div>
    <b-card>
      <h2 class="mb-2">Extrato Financeiro</h2>
      
      <!-- Filtros -->
      <b-row class="mb-2">
        <b-col md="6" sm="6" class="mb-1">
          <b-form-group label="Data Inicial">
            <flat-pickr
              v-model="filters.startDate"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d' }"
              placeholder="Selecione a data inicial"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="6" sm="6" class="mb-1">
          <b-form-group label="Data Final">
            <flat-pickr
              v-model="filters.endDate"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d' }"
              placeholder="Selecione a data final"
            />
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row class="mb-2">
        <b-col>
          <b-button variant="primary" @click="fetchLogs(true)">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span>Filtrar</span>
          </b-button>
          
          <b-button variant="outline-secondary" class="ml-1" @click="resetFilters">
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span>Limpar</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    
    <!-- Exibição dos logs por data -->
    <b-card
      v-for="(summary, date) in summaries"
      :key="date"
      class="mb-2"
    >
      <template #header>
        <h4 class="mb-0">{{ formatDate(date) }}</h4>
      </template>
      
      <b-list-group flush>
        <!-- Bônus Binário -->
        <b-list-group-item
          v-if="summary.binary.total !== 0"
          class="d-flex justify-content-between align-items-center py-2"
        >
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <b-avatar
                variant="primary"
                size="sm"
              >
                <feather-icon icon="Share2Icon" size="16" />
              </b-avatar>
            </div>
            <div>
              <h5 class="mb-0">Bônus de Rede (Binário)</h5>
            </div>
          </div>
          
          <div class="text-right">
            <h5 :class="summary.binary.total >= 0 ? 'text-success' : 'text-danger'" class="mb-0">
              {{ formatCurrency(summary.binary.total) }}
            </h5>
          </div>
        </b-list-group-item>
        
        <!-- Rendimento Robô -->
        <b-list-group-item
          v-if="summary.robo.total !== 0"
          class="d-flex justify-content-between align-items-center py-2"
        >
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <b-avatar
                variant="warning"
                size="sm"
              >
                <feather-icon icon="TrendingUpIcon" size="16" />
              </b-avatar>
            </div>
            <div>
              <h5 class="mb-0">Rendimento do Robô</h5>
              <small class="text-muted">Valor em porcentagem</small>
            </div>
          </div>
          
          <div class="text-right">
            <h5 :class="summary.robo.total >= 0 ? 'text-success' : 'text-danger'" class="mb-0">
              {{ formatPercentage(summary.robo.total) }}
            </h5>
          </div>
        </b-list-group-item>
        
        <!-- Bônus Residual -->
        <b-list-group-item
          v-if="summary.residual.total !== 0"
          class="d-flex justify-content-between align-items-center py-2"
        >
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <b-avatar
                variant="info"
                size="sm"
              >
                <feather-icon icon="UsersIcon" size="16" />
              </b-avatar>
            </div>
            <div>
              <h5 class="mb-0">Bônus Residual</h5>
            </div>
          </div>
          
          <div class="text-right">
            <h5 :class="summary.residual.total >= 0 ? 'text-success' : 'text-danger'" class="mb-0">
              {{ formatCurrency(summary.residual.total) }}
            </h5>
          </div>
        </b-list-group-item>
        
        <!-- Outros Bônus -->
        <b-list-group-item
          v-if="summary.bonus.total !== 0"
          class="d-flex justify-content-between align-items-center py-2"
        >
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <b-avatar
                variant="success"
                size="sm"
              >
                <feather-icon icon="GiftIcon" size="16" />
              </b-avatar>
            </div>
            <div>
              <h5 class="mb-0">Bônus</h5>
            </div>
          </div>
          
          <div class="text-right">
            <h5 :class="summary.bonus.total >= 0 ? 'text-success' : 'text-danger'" class="mb-0">
              {{ formatCurrency(summary.bonus.total) }}
            </h5>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    
    <!-- Paginação -->
    <div class="d-flex justify-content-center mt-2">
      <b-pagination
        v-model="currentPage"
        :total-rows="pagination.totalDays"
        :per-page="pagination.limit"
        @change="handlePageChange"
        class="mb-0"
      ></b-pagination>
    </div>
    
    <!-- Mensagem quando não há dados -->
    <b-card v-if="Object.keys(summaries).length === 0 && !isLoading">
      <div class="text-center p-5">
        <feather-icon icon="SearchIcon" size="32" />
        <h4 class="mt-2">Nenhum registro encontrado</h4>
        <p>Tente ajustar os filtros ou selecione outro período.</p>
      </div>
    </b-card>
    
    <!-- Loading spinner -->
    <b-overlay :show="isLoading" rounded="sm" spinner-variant="primary">
      <b-card v-if="isLoading" style="min-height: 300px">
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-spinner label="Carregando..." />
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BListGroup,
  BListGroupItem,
  BAvatar,
  BSpinner,
  BOverlay,
  BPagination,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ref, onMounted, computed } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BSpinner,
    BOverlay,
    BPagination,
    flatPickr,
    vSelect,
  },
  
  setup() {
    const summaries = ref({})
    const isLoading = ref(false)
    const currentPage = ref(1)
    const pagination = ref({
      totalDays: 0,
      totalPages: 0,
      currentPage: 1,
      limit: 7,
      hasNextPage: false,
      hasPrevPage: false
    })
    
    const filters = ref({
      startDate: null,
      endDate: null
    })
    
    const formatDate = (dateString) => {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(dateString).toLocaleDateString('en-US', options)
    }
    
    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value) + ' USDT'
    }
    
    const formatPercentage = (value) => {
      return `${value.toFixed(2)}%`
    }
    
    const handlePageChange = (page) => {
      currentPage.value = page
      fetchLogs(false)
    }
    
    const fetchLogs = async (showLoading = true) => {
      try {
        if (showLoading) {
          isLoading.value = true
        }
        
        const params = {
          page: currentPage.value,
          limit: 7
        }
        
        if (filters.value.startDate) params.startDate = filters.value.startDate
        if (filters.value.endDate) params.endDate = filters.value.endDate
        
        const response = await axios.get('/customer/extrato', { params })
        
        if (response.data.success) {
          summaries.value = response.data.data.summaries
          pagination.value = response.data.data.pagination
          currentPage.value = response.data.data.pagination.currentPage
        } else {
          console.error('Erro ao buscar logs:', response.data.message)
        }
      } catch (error) {
        console.error('Erro ao buscar logs:', error)
      } finally {
        isLoading.value = false
      }
    }
    
    const resetFilters = () => {
      filters.value = {
        startDate: null,
        endDate: null
      }
      currentPage.value = 1
      fetchLogs(true)
    }
    
    onMounted(() => {
      fetchLogs(true)
    })
    
    return {
      summaries,
      isLoading,
      currentPage,
      pagination,
      filters,
      formatDate,
      formatCurrency,
      formatPercentage,
      handlePageChange,
      fetchLogs,
      resetFilters,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style> 